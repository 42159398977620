import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProvider } from '@employee-experience/common/lib/RouteComponentProvider';
import Tenant from './TestHarnessCode/Components/Tenants';
import {QuickTest} from './TestHarnessCode/Components/QuickTest';
import {BulkDelete} from './TestHarnessCode/Components/BulkDelete';

export function Routes(): React.ReactElement {
    return (
        <Switch>
            <Route path="/" component={Tenant} exact={true} />
            <Route path="/GenerateLoad" component={QuickTest} exact={true} />
            <Route path="/BulkDelete" component={BulkDelete} exact={true} />
            <Route component={Tenant} />
            <RouteComponentProvider
                path="/dynamic-redux-hooks"
                config={{
                    script: '/bundles/dynamic-redux-hooks.js',
                    name: 'DynamicReduxHooks'
                }}
            />
            <RouteComponentProvider
                path="/dynamic-redux-class"
                config={{
                    script: '/bundles/dynamic-redux-class.js',
                    name: 'DynamicReduxClass'
                }}
            />
            <RouteComponentProvider
                path="/dynamic-sub-routes"
                config={{
                    script: '/bundles/dynamic-sub-routes.js',
                    name: 'DynamicSubRoutes'
                }}
            />
            <RouteComponentProvider
                path="/dynamic-custom-props"
                config={{
                    script: '/bundles/dynamic-custom-props.js',
                    name: 'DynamicCustomProps'
                }}
            />
            <RouteComponentProvider
                path="/hello-world"
                config={{
                    script: 'https://ee.azureedge.net/eedev/hello-world.js',
                    name: 'HelloWorld'
                }}
            />
        </Switch>
    );
}
