import {
    IRequestAboutInfo,
    IReceiveAboutInfo,
    HelpPanelActionType
} from './HelpPanel.action-types';

export function requestAboutInfo(): IRequestAboutInfo {
    return {
        type: HelpPanelActionType.REQUEST_ABOUT_INFO
    };
}

export function receiveAboutInfo(supportEmailId: string): IReceiveAboutInfo {
    return {
        type: HelpPanelActionType.RECEIVE_ABOUT_INFO,
        supportEmailId
    };
}