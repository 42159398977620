import * as React from 'react';
import Select from 'react-dropdown-select';
import { Constant } from '../Shared/Constants';
import '../Styles/Styles.css';
import { fetchTenantInfo, GenerateLoad } from '../Shared/Services';
import { appContext } from '../../App';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../Utils/TelemetryHelpers';
import { generateGuid } from '../Utils/Common';

export function QuickTest(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const [dropdownValue, setDropdownValue] = React.useState([]);
    const [approverList, setApproverDropdownValue] = React.useState([]);
    const [state, setState] = React.useState({ Load: Constant.DEFAULT_LOAD, BatchSize: Constant.DEFAULT_BATCH_SIZE });
    const [tenantName, setTenantName] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const [approvarName, setApprovarName] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const [shouldVisible, setShouldVisible] = React.useState({ sideForm: false });
    const [message, setMessage] = React.useState({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
    const { context } = React.useContext(appContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [loadError, setLoadError] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const [batchError, setBatchError] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const [tenantError, setTenantError] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const [approverError, setApproverError] = React.useState(Constant.DEFAULT_EMPTY_STRING);

    React.useEffect(() => {
        if (context.environment !== Constant.DEFAULT_EMPTY_STRING) {
            setDropdownValue([]);
            setApproverDropdownValue([]);
            const guid = generateGuid();
            fetchTenantInfo(context.environment, context.authToken, context.userAlias, guid.messageId, guid.xcv)
                .then(
                    (response: {
                        data: { tenantEntities: { rowKey: string; appName: string }[]; approverList: string };
                    }) => {
                        const listValues = response.data.tenantEntities.map(
                            (item: { rowKey: string; appName: string }) => {
                                return { value: item.rowKey, label: item.appName };
                            }
                        );
                        setDropdownValue(listValues);
                        const approverListValues = response.data.approverList.split(';').map((item: string) => {
                            return { value: item, label: item };
                        });
                        setApproverDropdownValue(approverListValues);
                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'QuickTest',
                            'MSApprovals.QuickTest.fetchTenantInfo.Success',
                            TrackingEventId.FetchTenantInfoSuccess,
                            {
                                environment: context.environment,
                                userAlias: context.userAlias,
                                messageId: guid.messageId,
                                xcv: guid.xcv
                            }
                        );
                    }
                )
                .catch(error => {
                    setDropdownValue([]);
                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'QuickTest',
                        'MSApprovals.QuickTest.fetchTenantInfo.failure',
                        TrackingEventId.FetchTenantInfoFailure,
                        error,
                        {
                            environment: context.environment,
                            userAlias: context.userAlias,
                            messageId: guid.messageId,
                            xcv: guid.xcv
                        }
                    );
                });
        }
    }, [context.environment]);

    function handleTextChange(evt: { target: { value: string; name: string } }) {
        const value = evt.target.value;
        const regex = /^[0-9\b]+$/;

        if (value === Constant.DEFAULT_EMPTY_STRING || regex.test(value)) {
            setState({
                ...state,
                [evt.target.name]: value
            });
        }
    }

    const handleDropDownChange1 = (selectedOption: any[]) => {
        setTenantName(selectedOption[0].value);
    };

    const handleDropDownChange2 = (selectedOption: any[]) => {
        if (approverList.length > 0) {
            setApprovarName(selectedOption[0].value);
        }
    };

    const onSubmit = () => {
        setMessage({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
        setShouldVisible({ sideForm: true });
        setIsLoading(true);
        setTenantError(Constant.DEFAULT_EMPTY_STRING);
        setApproverError(Constant.DEFAULT_EMPTY_STRING);
        setLoadError(Constant.DEFAULT_EMPTY_STRING);
        setBatchError(Constant.DEFAULT_EMPTY_STRING);

        if (
            tenantName != Constant.DEFAULT_EMPTY_STRING &&
            approvarName != Constant.DEFAULT_EMPTY_STRING &&
            state.Load != Constant.DEFAULT_EMPTY_STRING &&
            state.BatchSize != Constant.DEFAULT_EMPTY_STRING
        ) {
            const guid = generateGuid();
            GenerateLoad(
                tenantName,
                approvarName,
                state.Load,
                state.BatchSize,
                context.authToken,
                context.environment,
                context.userAlias,
                guid.messageId,
                guid.xcv
            )
                .then((response: { status: number; data: { successDocuments: string } }) => {
                    if (response.status === Constant.STATUSCODE_OK) {
                        setIsLoading(false);
                        setMessage({
                            visible: true,
                            value: response.data.successDocuments.replace(/(^[,\s]+)|([,\s]+$)/g, '')
                        });
                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'QuickTest',
                            'MSApprovals.QuickTest.GenerateLoad.Success',
                            TrackingEventId.GenerateLoadSuccess,
                            {
                                environment: context.environment,
                                userAlias: context.userAlias,
                                messageId: guid.messageId,
                                xcv: guid.xcv
                            }
                        );
                    }
                })
                .catch(error => {
                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'QuickTest',
                        'MSApprovals.QuickTest.GenerateLoad.failure',
                        TrackingEventId.GenerateLoadFailure,
                        errorMessage,
                        {
                            environment: context.environment,
                            userAlias: context.userAlias,
                            messageId: guid.messageId,
                            xcv: guid.xcv
                        }
                    );
                });
        } else {
            setShouldVisible({ sideForm: false });

            if (tenantName == Constant.DEFAULT_EMPTY_STRING) {
                setTenantError(Constant.ERROR_MESSAGE);
            }

            if (approvarName == Constant.DEFAULT_EMPTY_STRING) {
                setApproverError(Constant.ERROR_MESSAGE);
            }

            if (state.Load == Constant.DEFAULT_EMPTY_STRING) {
                setLoadError(Constant.ERROR_MESSAGE);
            }

            if (state.BatchSize == Constant.DEFAULT_EMPTY_STRING) {
                setBatchError(Constant.ERROR_MESSAGE);
            }
        }
    };

    return (
        <React.Fragment>
            <h3>{Constant.GENERATE_LOAD_HEADER}</h3>
            <br />
            <div className="th-flex-parent">
                <div className="th-flex-child3">
                    <table className="th-quicktest-table">
                        <tr>
                            <td className="th-quicktest-td">{Constant.TENANT_NAME}</td>
                            <td className="th-quicktest-td th-width">
                                <Select
                                    className="th-tenant-dropdown"
                                    name="TenantName"
                                    options={dropdownValue}
                                    loading={dropdownValue.length > 0 ? false : true}
                                    onChange={(val: any) => handleDropDownChange1(val)}
                                    searchable={false}
                                    disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                    required={true}
                                    values={[...tenantName]}
                                />
                            </td>
                            {tenantError.length > 0 && <td className="th-error-message">{tenantError}</td>}
                        </tr>
                        <tr>
                            <td className="th-quicktest-td">{Constant.APPROVER_NAME}</td>
                            <td className="th-quicktest-td th-width">
                                <Select
                                    className="th-tenant-dropdown"
                                    name="ApprovarName"
                                    options={approverList}
                                    loading={approverList.length > 0 ? false : true}
                                    onChange={(val: any) => handleDropDownChange2(val)}
                                    searchable={false}
                                    disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                    values={
                                        approverList.length > 0
                                            ? [approverList.find(x => x.value === context.userAlias)]
                                            : []
                                    }
                                />
                            </td>
                            {approverError.length > 0 && <td className="th-error-message">{approverError}</td>}
                        </tr>
                        <tr>
                            <td className="th-quicktest-td">{Constant.LOAD}</td>
                            <td className="th-quicktest-td th-width">
                                <input
                                    data-testid="txt_Load"
                                    className="th-tenant-dropdown"
                                    type="text"
                                    name="Load"
                                    value={state.Load}
                                    onChange={handleTextChange}
                                    disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                    required={true}
                                ></input>
                            </td>
                            {loadError.length > 0 && <td className="th-error-message">{loadError}</td>}
                        </tr>
                        <tr>
                            <td className="th-quicktest-td">{Constant.BATCH_SIZE}</td>
                            <td className="th-quicktest-td th-width">
                                <input
                                    data-testid="txt_BatchSize"
                                    className="th-tenant-dropdown"
                                    type="text"
                                    name="BatchSize"
                                    value={state.BatchSize}
                                    onChange={handleTextChange}
                                    disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                ></input>
                            </td>
                            {batchError.length > 0 && <td className="th-error-message">{batchError}</td>}
                        </tr>
                        <tr>
                            <td className="th-quicktest-td"></td>
                        </tr>
                        <tr>
                            <td className="th-quicktest-td">
                                <button
                                    className={
                                        context.environment == Constant.DEFAULT_EMPTY_STRING
                                            ? 'hidden'
                                            : 'th-btn th-btn-primary'
                                    }
                                    type="submit"
                                    onClick={onSubmit}
                                >
                                    {Constant.SUBMIT}
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                {shouldVisible.sideForm && (
                    <div className="th-flex-child2">
                        {isLoading && <Spinner label={Constant.SUBMIT_REQUEST} />}
                        {message.visible && (
                            <div className="th-success-message">
                                <p>{Constant.SUCCESS_MESSAGE_QUICK_TEST}</p>
                                <p>{message.value}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
