import * as React from 'react';
import Form from '@rjsf/fluent-ui';
import Select from 'react-dropdown-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Styles.css';
import { Constant } from '../Shared/Constants';
import { format } from 'react-string-format';
import { appContext } from '../../App';
import { fetchFormData, fetchTenantInfo, postFormData } from '../Shared/Services';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../Utils/TelemetryHelpers';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { generateGuid } from '../Utils/Common';

function Tenant(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const [dropdownValue, setDropdownValue] = React.useState({ tenantList: [], approverList: [] });
    const [isLoading, setIsLoading] = React.useState({ visible: true, value: Constant.DEFAULT_EMPTY_STRING });
    const [shouldVisible, setShouldVisible] = React.useState({
        actionBar: false,
        sideForm: false,
        enableSubmit: false
    });
    const [message, setMessage] = React.useState({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
    const [tenantId, setTenantId] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const { context } = React.useContext(appContext);
    var submitterName: string;

    const [state, setState] = React.useState({
        formJson: {},
        uiSchema: {},
        sampleData: {},
        ReportList: {
            ApprovalIdentifier: { DisplayDocumentNumber: Constant.DEFAULT_EMPTY_STRING },
            Approvers: []
        }
    });

    React.useEffect(() => {
        if (context.environment !== Constant.DEFAULT_EMPTY_STRING && context.environment !== undefined) {
            const guid = generateGuid();
            setDropdownValue({ tenantList: [], approverList: [] });
            fetchTenantInfo(context.environment, context.authToken, context.userAlias, guid.messageId, guid.xcv)
                .then(
                    (response: {
                        data: { tenantEntities: { rowKey: string; appName: string }[]; approverList: string };
                    }) => {
                        const listValues = response.data.tenantEntities.map(
                            (item: { rowKey: string; appName: string }) => {
                                return { value: item.rowKey, label: item.appName };
                            }
                        );

                        const approverListValues = response.data.approverList.split(';').map((item: string) => {
                            return { value: item, label: item };
                        });
                        setDropdownValue({ tenantList: listValues, approverList: approverListValues });
                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'Tenant',
                            'MSApprovals.Tenant.fetchTenantInfo.Success',
                            TrackingEventId.FetchTenantInfoSuccess,
                            {
                                environment: context.environment,
                                userAlias: context.userAlias,
                                messageId: guid.messageId,
                                xcv: guid.xcv
                            }
                        );
                    }
                )
                .catch(error => {
                    setDropdownValue({ tenantList: [], approverList: [] });
                    setMessage({ visible: true, value: Constant.EXCEPTION_MESSAGE });
                    trackException(
                        authClient,
                        telemetryClient,
                        'Tenant',
                        'MSApprovals.Tenant.fetchTenantInfo.failure',
                        TrackingEventId.FetchTenantInfoFailure,
                        error.response,
                        {
                            environment: context.environment,
                            userAlias: context.userAlias,
                            messageId: guid.messageId,
                            xcv: guid.xcv
                        }
                    );
                });
        }
    }, [context.environment]);

    const handleTenantDropdownChange = (selectedOption: any[]) => {
        setTenantId(selectedOption[0].value);
        setShouldVisible({ actionBar: true, sideForm: true, enableSubmit: false });
        setMessage({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
        setIsLoading({ visible: true, value: Constant.LOAD_FORM_DATA });
        setState({
            formJson: {},
            uiSchema: {},
            sampleData: {},
            ReportList: {
                ApprovalIdentifier: { DisplayDocumentNumber: Constant.DEFAULT_EMPTY_STRING },
                Approvers: []
            }
        });

        if (dropdownValue.approverList.length > 0) {
            submitterName = dropdownValue.approverList[0].value;
        }
        const guid = generateGuid();
        fetchFormData(
            selectedOption[0].value,
            context.userAlias,
            submitterName,
            context.authToken,
            context.environment,
            guid.messageId,
            guid.xcv
        )
            .then((response: { data: { formSchema: any; uiSchema: any; sampleData: any; formData: any } }) => {
                setIsLoading({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
                setShouldVisible({ actionBar: true, sideForm: true, enableSubmit: true });
                setState({
                    formJson: response.data.formSchema,
                    uiSchema: response.data.uiSchema,
                    sampleData: response.data.sampleData,
                    ReportList: response.data.formData
                });
                trackBusinessProcessEvent(
                    authClient,
                    telemetryClient,
                    'Tenant',
                    'MSApprovals.Tenant.fetchFormData.Success',
                    TrackingEventId.FetchFormDataSuccess,
                    {
                        environment: context.environment,
                        userAlias: context.userAlias,
                        messageId: guid.messageId,
                        xcv: guid.xcv
                    }
                );
            })
            .catch((error) => {
                const errorMessage = error.response ?? error;
                setMessage({ visible: true, value: Constant.EXCEPTION_MESSAGE });
                setIsLoading({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
                trackException(
                    authClient,
                    telemetryClient,
                    'Tenant',
                    'MSApprovals.Tenant.fetchFormData.failure',
                    TrackingEventId.FetchFormDataFailure,
                    errorMessage,
                    {
                        environment: context.environment,
                        userAlias: context.userAlias,
                        messageId: guid.messageId,
                        xcv: guid.xcv
                    }
                );
            });
    };

    const onSubmit = () => {
        document.getElementById('submit-button-inside').click();
    };

    const onSubmitInside = (event: any) => {
        const guid = generateGuid();
        setIsLoading({ visible: true, value: Constant.SUBMIT_FORM_DATA });
        postFormData(
            event.formData,
            tenantId,
            context.authToken,
            context.environment,
            context.userAlias,
            guid.messageId,
            guid.xcv
        )
            .then((response: { status: number; statusText: string }) => {
                if (response.status === Constant.STATUSCODE_OK) {
                    const ddNumber = event.formData.ApprovalIdentifier.DisplayDocumentNumber;
                    const approvers = event.formData.Approvers.map((e: { Alias: string }) => {
                        return e.Alias;
                    });
                    const successMessage = format(
                        Constant.SUCCESS_MESSAGE_NOTIFICATION,
                        ddNumber,
                        approvers.toString()
                    );
                    setMessage({ visible: true, value: successMessage });
                    setShouldVisible({ actionBar: true, sideForm: true, enableSubmit: false });
                    setIsLoading({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'Tenant',
                        'MSApprovals.Tenant.postFormData.Success',
                        TrackingEventId.postFormDataSuccess,
                        {
                            environment: context.environment,
                            userAlias: context.userAlias,
                            messageId: guid.messageId,
                            xcv: guid.xcv
                        }
                    );
                }
            })
            .catch((error) => {
                const errorMessage = error.response ?? error;
                setMessage({ visible: true, value: Constant.EXCEPTION_MESSAGE });
                setIsLoading({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
                trackException(
                    authClient,
                    telemetryClient,
                    'Tenant',
                    'MSApprovals.Tenant.postFormData.failure',
                    TrackingEventId.postFormDataFailure,
                    errorMessage,
                    {
                        environment: context.environment,
                        userAlias: context.userAlias,
                        messageId: guid.messageId,
                        xcv: guid.xcv
                    }
                );
            });
    };

    return (
        <React.Fragment>
            <h3>{Constant.GENERATE_TEST_REQUEST}</h3>
            <br />
            <div className="th-flex-parent">
                <div className="th-flex-child1">
                    <table>
                        <tr>
                            <td className="th-quicktest-td">{Constant.TENANT_NAME}</td>
                            <td className="th-quicktest-td th-width">
                                <Select
                                    className="th-tenant-dropdown"
                                    disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                    options={dropdownValue.tenantList}
                                    loading={dropdownValue.tenantList.length > 0 ? false : true}
                                    onChange={(val: any) => handleTenantDropdownChange(val)}
                                    searchable={false}
                                    placeholder={Constant.TENANT_PLACEHOLDER}
                                    values={[...tenantId]}
                                />
                            </td>
                        </tr>
                    </table>
                </div>
                {shouldVisible.sideForm && (
                    <div className="th-flex-child2">
                        {isLoading.visible && <Spinner label={isLoading.value} />}
                        {!message.visible && !isLoading.visible ? (
                            <Form
                                schema={state.formJson}
                                uiSchema={state.uiSchema}
                                formData={state.ReportList}
                                onSubmit={onSubmitInside}
                            >
                                <div className="hidden">
                                    <button id="submit-button-inside" className="btn-lg btn-block" type="submit">
                                        {Constant.SUBMIT}
                                    </button>
                                </div>
                            </Form>
                        ) : (
                            <p className="th-success-message">{message.value}</p>
                        )}
                    </div>
                )}
            </div>
            {shouldVisible.actionBar && (
                <div className="th-tenant-actionBar">
                    <span>
                        <button
                            disabled={!shouldVisible.enableSubmit && isLoading.visible}
                            className={
                                !shouldVisible.enableSubmit && isLoading.visible
                                    ? 'th-btn th-btn-disabled'
                                    : 'th-btn th-btn-primary'
                            }
                            type="submit"
                            onClick={onSubmit}
                        >
                            {Constant.SUBMIT}
                        </button>
                    </span>
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(Tenant);
