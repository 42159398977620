import * as React from 'react';
import Select from 'react-dropdown-select';
import { Constant } from '../Shared/Constants';
import '../Styles/Styles.css';
import { appContext } from '../../App';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { fetchTenantInfo, PostBulkDelete } from '../Shared/Services';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../Utils/TelemetryHelpers';
import { generateGuid } from '../Utils/Common';

export function BulkDelete(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const [dropdownValue, setDropdownValue] = React.useState([]);
    const [approverList, setApproverList] = React.useState([]);
    const [state, setState] = React.useState({
        Days: Constant.DEFAULT_EMPTY_STRING,
        DocNumber: Constant.DEFAULT_EMPTY_STRING
    });
    const [tenantName, setTenantName] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const [approvarName, setApprovarName] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const [shouldVisible, setShouldVisible] = React.useState({ sideForm: false });
    const [successMessage, setSuccessMessage] = React.useState({
        visible: false,
        value: Constant.DEFAULT_EMPTY_STRING
    });
    const [failureMessage, setFailureMessage] = React.useState({
        visible: false,
        value: Constant.DEFAULT_EMPTY_STRING
    });
    const [warningMessage, setWarningMessage] = React.useState({
        visible: false,
        value: Constant.DEFAULT_EMPTY_STRING
    });
    const { context } = React.useContext(appContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [commonError, setCommonError] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const [tenantError, setTenantError] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const [approverError, setApproverError] = React.useState(Constant.DEFAULT_EMPTY_STRING);
    const infoLogo = '/icons/info.png';

    React.useEffect(() => {
        if (context.environment !== Constant.DEFAULT_EMPTY_STRING) {
            setDropdownValue([]);
            setApproverList([]);
            const guid = generateGuid();
            fetchTenantInfo(context.environment, context.authToken, context.userAlias, guid.messageId, guid.xcv)
                .then(
                    (response: {
                        data: { tenantEntities: { rowKey: string; appName: string }[]; approverList: string };
                    }) => {
                        const listValues = response.data.tenantEntities.map(
                            (item: { rowKey: string; appName: string }) => {
                                return { value: item.rowKey, label: item.appName };
                            }
                        );
                        setDropdownValue(listValues);

                        const approverListValues = response.data.approverList.split(';').map((item: string) => {
                            return { value: item, label: item };
                        });
                        setApproverList(approverListValues);
                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'BulkDelete',
                            'MSApprovals.BulkDelete.fetchTenantInfo.Success',
                            TrackingEventId.FetchTenantInfoSuccess,
                            {
                                environment: context.environment,
                                userAlias: context.userAlias,
                                messageId: guid.messageId,
                                xcv: guid.xcv
                            }
                        );
                    }
                )
                .catch(error => {
                    setDropdownValue([]);
                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'BulkDelete',
                        'MSApprovals.BulkDelete.fetchTenantInfo.failure',
                        TrackingEventId.FetchTenantInfoFailure,
                        errorMessage,
                        {
                            environment: context.environment,
                            userAlias: context.userAlias,
                            messageId: guid.messageId,
                            xcv: guid.xcv
                        }
                    );
                });
        }
    }, [context.environment]);

    function handleTextChange(evt: { target: { value: string; name: string } }) {
        const value = evt.target.value;
        const regex = /^[0-9\b]+$/;

        if (evt.target.name === 'Days') {
            if (value === '' || regex.test(value)) {
                setState({
                    ...state,
                    [evt.target.name]: value
                });
            }
        } else {
            setState({
                ...state,
                [evt.target.name]: value
            });
        }
    }

    const handleDropDownChange1 = (selectedOption: any[]) => {
        setTenantName(selectedOption[0].value);
    };

    const handleDropDownChange2 = (selectedOption: any[]) => {
        if (approverList.length > 0) {
            setApprovarName(selectedOption[0].value);
        }
    };

    const onSubmit = () => {
        setShouldVisible({ sideForm: true });
        setIsLoading(true);
        setSuccessMessage({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
        setFailureMessage({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
        setWarningMessage({ visible: false, value: Constant.DEFAULT_EMPTY_STRING });
        setTenantError(Constant.DEFAULT_EMPTY_STRING);
        setApproverError(Constant.DEFAULT_EMPTY_STRING);
        setCommonError(Constant.DEFAULT_EMPTY_STRING);

        if (
            tenantName != Constant.DEFAULT_EMPTY_STRING &&
            approvarName != Constant.DEFAULT_EMPTY_STRING &&
            (state.Days != Constant.DEFAULT_EMPTY_STRING || state.DocNumber != Constant.DEFAULT_EMPTY_STRING)
        ) {
            const guid = generateGuid();
            PostBulkDelete(
                tenantName,
                approvarName,
                state.Days,
                state.DocNumber,
                context.authToken,
                context.environment,
                context.userAlias,
                guid.messageId,
                guid.xcv
            )
                .then(
                    (response: {
                        status: number;
                        data: { bulkSuccessDocuments: string; bulkFailureDocuments: string; invalidDocuments: string };
                    }) => {
                        if (response.status === Constant.STATUSCODE_OK) {
                            setIsLoading(false);
                            if (response.data.bulkSuccessDocuments.length > 0) {
                                setSuccessMessage({
                                    visible: true,
                                    value: response.data.bulkSuccessDocuments.replace(/(^[,\s]+)|([,\s]+$)/g, '')
                                });
                            }

                            if (response.data.bulkFailureDocuments.length > 0) {
                                setFailureMessage({
                                    visible: true,
                                    value: response.data.bulkFailureDocuments.replace(/(^[,\s]+)|([,\s]+$)/g, '')
                                });
                            }

                            if (response.data.invalidDocuments.length > 0) {
                                setWarningMessage({
                                    visible: true,
                                    value: response.data.invalidDocuments.replace(/(^[,\s]+)|([,\s]+$)/g, '')
                                });
                            }
                        }
                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'BulkDelete',
                            'MSApprovals.BulkDelete.PostBulkDelete.Success',
                            TrackingEventId.PostBulkDeleteSuccess,
                            {
                                environment: context.environment,
                                userAlias: context.userAlias,
                                messageId: guid.messageId,
                                xcv: guid.xcv
                            }
                        );
                    }
                )
                .catch(error => {
                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'BulkDelete',
                        'MSApprovals.BulkDelete.PostBulkDelete.failure',
                        TrackingEventId.PostBulkDeleteFailure,
                        errorMessage,
                        {
                            environment: context.environment,
                            userAlias: context.userAlias,
                            messageId: guid.messageId,
                            xcv: guid.xcv
                        }
                    );
                });
        } else {
            setShouldVisible({ sideForm: false });

            if (tenantName == Constant.DEFAULT_EMPTY_STRING) {
                setTenantError(Constant.ERROR_MESSAGE);
            }

            if (approvarName == Constant.DEFAULT_EMPTY_STRING) {
                setApproverError(Constant.ERROR_MESSAGE);
            }

            if (state.Days == Constant.DEFAULT_EMPTY_STRING && state.DocNumber == Constant.DEFAULT_EMPTY_STRING) {
                setCommonError(Constant.ERROR_MESSAGE);
            }
        }
    };

    return (
        <React.Fragment>
            <h3>{Constant.BULK_DELETE_HEADER}</h3>
            <br />
            <div className="th-flex-parent">
                <div className="th-flex-child4">
                    <table className="th-quicktest-table">
                        <tr>
                            <td className="th-quicktest-td">{Constant.TENANT_NAME}</td>
                            <td className="th-quicktest-td th-width">
                                <Select
                                    className="th-tenant-dropdown"
                                    name="TenantName"
                                    options={dropdownValue}
                                    loading={dropdownValue.length > 0 ? false : true}
                                    onChange={(val: any) => handleDropDownChange1(val)}
                                    searchable={false}
                                    disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                    values={[...tenantName]}
                                />
                            </td>
                            {tenantError.length > 0 && <td className="th-error-message">{tenantError}</td>}
                        </tr>
                        <tr>
                            <td className="th-quicktest-td">{Constant.APPROVER_NAME}</td>
                            <td className="th-quicktest-td th-width">
                                <Select
                                    className="th-tenant-dropdown"
                                    name="ApprovarName"
                                    options={approverList}
                                    loading={approverList.length > 0 ? false : true}
                                    onChange={(val: any) => handleDropDownChange2(val)}
                                    searchable={false}
                                    disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                    values={
                                        approverList.length > 0
                                            ? [approverList.find(x => x.value === context.userAlias)]
                                            : []
                                    }
                                />
                            </td>
                            {approverError.length > 0 && <td className="th-error-message">{approverError}</td>}
                        </tr>
                        <tr>
                            <td className="th-quicktest-td">{Constant.DELETE_AS_PER_DAYS}</td>
                            <td className="th-quicktest-td th-width">
                                <input
                                    data-testid="txt_Days"
                                    className="th-tenant-dropdown"
                                    type="text"
                                    name="Days"
                                    value={state.Days}
                                    onChange={handleTextChange}
                                    disabled={
                                        context.environment == Constant.DEFAULT_EMPTY_STRING ||
                                        state.DocNumber.length > 0
                                            ? true
                                            : false
                                    }
                                ></input>
                            </td>
                            {commonError.length > 0 && <td className="th-error-message">{commonError}</td>}
                        </tr>
                        <tr>
                            <td className="th-quicktest-td"></td>
                            <td className="th-quicktest-td">{Constant.OR}</td>
                        </tr>
                        <tr>
                            <td className="th-quicktest-td">{Constant.DOCUMENT_NUMBERS}</td>
                            <td className="th-quicktest-td th-width">
                                <input
                                    data-testid="txt_DocNumber"
                                    className="th-tenant-dropdown"
                                    type="text"
                                    name="DocNumber"
                                    value={state.DocNumber}
                                    onChange={handleTextChange}
                                    disabled={
                                        context.environment == Constant.DEFAULT_EMPTY_STRING || state.Days.length > 0
                                            ? true
                                            : false
                                    }
                                ></input>
                            </td>
                            <td>
                                <img
                                    src={infoLogo}
                                    alt="info Logo"
                                    className="th-image-info"
                                    title="Document Number can accept multiple values seperated by comma."
                                ></img>
                            </td>
                            {commonError.length > 0 && <td className="th-error-message">{commonError}</td>}
                        </tr>
                        <tr>
                            <td className="th-quicktest-td"></td>
                        </tr>
                        <tr>
                            <td className="th-quicktest-td" colSpan={2}>
                                <button
                                    className={
                                        context.environment == Constant.DEFAULT_EMPTY_STRING
                                            ? 'hidden'
                                            : 'th-btn th-btn-primary'
                                    }
                                    type="submit"
                                    onClick={onSubmit}
                                >
                                    {Constant.SUBMIT}
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                {shouldVisible.sideForm && (
                    <div className="th-flex-child2">
                        {isLoading && <Spinner label={Constant.SUBMIT_REQUEST} />}
                        {successMessage.visible && (
                            <React.Fragment>
                                <div className="th-success-message">
                                    <p>{Constant.SUCCESS_MESSAGE_BULK_DELETE}</p>
                                    <p>{successMessage.value}</p>
                                </div>
                                <br />
                            </React.Fragment>
                        )}
                        {failureMessage.visible && (
                            <React.Fragment>
                                <div className="th-failure-message">
                                    <p>{Constant.FAILURE_MESSAGE_BULK_DELETE}</p>
                                    <p>{failureMessage.value}</p>
                                </div>{' '}
                                <br />
                            </React.Fragment>
                        )}
                        {warningMessage.visible && (
                            <React.Fragment>
                                <div className="th-warning-message">
                                    <p>{Constant.WARNING_MESSAGE_BULK_DELETE}</p>
                                    <p>{warningMessage.value}</p>
                                </div>{' '}
                                <br />
                            </React.Fragment>
                        )}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
