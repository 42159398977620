import * as React from 'react';
import { render } from 'react-dom';
import { CoherenceCustomizations } from '@cseo/styles';
import { Customizer, Stack } from 'office-ui-fabric-react';
import { BrowserRouter } from 'react-router-dom';
import { ShellStyles } from '@employee-experience/shell/lib/Shell';
import { Header } from '@employee-experience/shell/lib/Header';
import { Nav } from '@employee-experience/shell/lib/Nav';
import { Main } from '@employee-experience/shell/lib/Main';
import { initializeIcons } from '@uifabric/icons';
import { Routes } from './Routes';
import { usePersistentReducer } from './TestHarnessCode/Shared/PersistantReducer';
import {
    sharedComponentsPersistentReducerName,
    sharedComponentsPersistentReducer,
    SharedComponentsPersistentInitialState
} from './TestHarnessCode/Shared/SharedComponents.persistent-reducer';
import { ReduxContext } from '@employee-experience/common';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { IComponentsAppState } from './TestHarnessCode/Shared/SharedComponents.types';
import HelpPanel from './TestHarnessCode/Shared/HelpPanel/HelpPanel';
import { SecondaryHeader } from './TestHarnessCode/Shared/SecondaryHeader';
import './App.css';
import { ICoherenceHeaderProps } from '@cseo/controls';
import { useLoginOnStartupWithOptions } from './TestHarnessCode/Utils/useLoginOnStartupWithOptions';
import {
    sharedComponentsReducerName,
    sharedComponentsInitialState
} from './TestHarnessCode/Shared/SharedComponents.reducer';
import './TestHarnessCode/Styles/Styles.css';
import { navigationConfig } from './TestHarnessCode/Shared/Navigation';
import { Constant } from './TestHarnessCode/Shared/Constants';

export const appContext = React.createContext(null);

function App(): React.ReactElement {
    initializeIcons();
    useLoginOnStartupWithOptions(true, { scopes: ['https://graph.microsoft.com/.default'] });
    usePersistentReducer(
        sharedComponentsPersistentReducerName,
        sharedComponentsPersistentReducer
    );
    const { useSelector } = React.useContext(ReduxContext);
    const { telemetryClient: telemetryHook } = React.useContext(ComponentContext);
    const { teachingBubbleVisibility } = useSelector(
        (state: IComponentsAppState) =>
            state.SharedComponentsPersistentReducer || SharedComponentsPersistentInitialState
    );

    const { isPanelOpen } = useSelector(
        (state: IComponentsAppState) => state.dynamic?.[sharedComponentsReducerName] || sharedComponentsInitialState
    );

    const headerConfig: ICoherenceHeaderProps = {
        headerLabel: __APP_NAME__,
        appNameSettings: {
            label: __APP_NAME__
        },
        farRightSettings: {
            helpSettings: {
                body: <HelpPanel />,
                telemetryHook,
                ...(teachingBubbleVisibility && { isOpen: false })
            }
        },
        telemetryHook
    }


    const [context, setContext] = React.useState({
        authToken: Constant.DEFAULT_EMPTY_STRING,
        environment: Constant.DEFAULT_EMPTY_STRING,
        userAlias: Constant.DEFAULT_EMPTY_STRING
    })

    return (
        <Customizer {...CoherenceCustomizations}>
            <ShellStyles />
            <BrowserRouter>
                <Header {...headerConfig} />
                <div>
                    <appContext.Provider value={{ context, setContext }}>
                        <Stack horizontal className={isPanelOpen ? 'ms-hiddenSm' : ''}>
                            <Stack.Item>
                                <div className="navContainer">
                                    <Nav groups={navigationConfig} />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow>
                                <SecondaryHeader />
                            </Stack.Item>
                        </Stack>
                        <Main id="main" tabIndex={-1}>
                            <div className="th-main-routes">
                                <Routes />
                            </div>
                        </Main>
                    </appContext.Provider>
                </div>
            </BrowserRouter>
        </Customizer>
    );
}

export default App;
