export enum HelpPanelActionType {
    REQUEST_ABOUT_INFO = 'REQUEST_ABOUT_INFO',
    RECEIVE_ABOUT_INFO = 'RECEIVE_ABOUT_INFO',
}

export type HelpPanelAction =
    | IRequestAboutInfo
    | IReceiveAboutInfo;

export interface IRequestAboutInfo {
    type: HelpPanelActionType.REQUEST_ABOUT_INFO;
}

export interface IReceiveAboutInfo {
    type: HelpPanelActionType.RECEIVE_ABOUT_INFO;
    supportEmailId: string;
}