import { useContext, useEffect, useState } from 'react';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { TelemetryEvents } from '@employee-experience/common/lib/Resources/TelemetryEvents';
import { IUser } from '@employee-experience/common/lib/Models/IUser';
import { ILoginOptions } from '@employee-experience/common/lib/Models/IAuthClient';

/* istanbul ignore file */
export function useLoginOnStartupWithOptions(shouldLogin?: boolean, loginOptions?: ILoginOptions): [IUser | null] {
    const { authClient, telemetryClient } = useContext(ComponentContext);
    const [user, setUser] = useState(null);
    useEffect(() => {
        telemetryClient.trackTrace({ message: TelemetryEvents.SessionStarted });
        if (user) return telemetryClient.setAuthenticatedUserContext(user.id);
    }, []);
    useEffect(() => {
        if (shouldLogin === false) return;
        if (user) return telemetryClient.setAuthenticatedUserContext(user.id);
        authClient
            .getUser()
            .then(user => {
                user ? setUser(user) : authClient.login(loginOptions).catch();
            })
            .catch(() => {
                authClient.login(loginOptions).catch();
            });
    }, [authClient, shouldLogin, telemetryClient, user, loginOptions]);
    return [user];
}
