export const Constant = {
    SUCCESS_MESSAGE_NOTIFICATION: "Success: The notification was sent out successfully for {0}, ({1}) as an approver!!!",
    DEFAULT_LOAD: "10",
    DEFAULT_BATCH_SIZE: "1",
    DEFAULT_EMPTY_STRING: "",
    ERROR_TEXT: "There is an error!",
    GENERATE_LOAD_HEADER: "Generate Load",
    GENERATE_TEST_REQUEST: "Generate Test Request",
    BULK_DELETE_HEADER: "Bulk Delete",
    ENVIRONMENT_NAME: "Environment Name: ",
    TENANT_NAME: "Tenant Name: ",
    APPROVER_NAME: "Approver: ",
    SUBMITTER_NAME: "Submitter: ",
    LOAD: "Load: ",
    BATCH_SIZE: "Batch Size: ",
    DELETE_AS_PER_DAYS: "Delete Data older than 'n' days: ",
    DOCUMENT_NUMBERS: "Document Numbers: ",
    OR: "(OR)",
    SUBMIT: "Submit",
    STATUSCODE_OK: 200,
    SUCCESS_MESSAGE_QUICK_TEST: "Successfully created request(s): ",
    SUCCESS_MESSAGE_BULK_DELETE: "Successfully deleted document(s): ",
    FAILURE_MESSAGE_BULK_DELETE: "Failed to delete document(s): ",
    WARNING_MESSAGE_BULK_DELETE: "Invalid Document Number(s): ",
    ERROR_MESSAGE: "* Required",
    LOAD_FORM_DATA: "Loading Form Data...",
    SUBMIT_FORM_DATA: "Submitting Form Data...",
    SUBMIT_REQUEST: "Submitting request...",
    TENANT_PLACEHOLDER: "Select Tenant...",
    APPROVER_PLACEHOLDER: "Select Approver...",
    SUBMITTER_PLACEHOLDER: "Select Submitter...",
    API_STATUS_CODE_NOT_AUTHORIZED: 403,
    EXCEPTION_MESSAGE: "Request Failed, Please reach out to MSApprovals Support team at msapprovalssup@microsoft.com."
}