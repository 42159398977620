/* eslint-disable prettier/prettier */
import * as React from 'react';
import * as Styled from '../Styles/Layout';
import { withReduxContext } from '@employee-experience/common/lib/withReduxContext';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Constant } from './Constants';
import { appContext } from '../../App';
import { fetchAuthToken, fetchEnvironmentList, fetchEnvironmentThroughPipeline } from './Services';
import { Dropdown, IDropdownOption, ResponsiveMode } from '@fluentui/react';
import { generateGuid } from '../Utils/Common';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../Utils/TelemetryHelpers';

function SecondaryHeader(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const [envValue, setEnvValue] = React.useState([]);
    const { context, setContext } = React.useContext(appContext);

    React.useEffect(() => {
        //get user alias
        authClient.getUser().then((user: { email: string }) => {
            var alias = user.email.split('@')[0];
            setContext((prevState: object) => ({ ...prevState, userAlias: alias }));
        });
        const guid = generateGuid();
        //get auth token
        fetchAuthToken(authClient)
            .then((token: string) => {
                setContext((prevState: object) => ({ ...prevState, authToken: token }));
                const guid = generateGuid();
                //get env list
                fetchEnvironmentList(token, context.userAlias, context.environment, guid.messageId, guid.xcv)
                    .then((response: { data: string[] }) => {
                        const envList = response.data.map((item: string) => {
                            return { key: item, text: item };
                        });
                        setEnvValue(envList);
                        setContext((prevState: any) => ({ ...prevState, environment: envList[0].text }));
                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'SecondaryHeader',
                            'MSApprovals.SecondaryHeader.fetchEnvironmentList.Success',
                            TrackingEventId.FetchEnvironmentListSuccess,
                            { environment: context.environment, userAlias: context.userAlias, messageId: guid.messageId, xcv: guid.xcv }
                        );
                    })
                    .catch(error => {
                        const errorMessage = error.response ?? error;
                        setEnvValue(error.response);
                        trackException(
                            authClient,
                            telemetryClient,
                            'SecondaryHeader',
                            'MSApprovals.SecondaryHeader.fetchEnvironmentList.failure',
                            TrackingEventId.FetchEnvironmentListFailure,
                            errorMessage,
                            { environment: context.environment, userAlias: context.userAlias, messageId: guid.messageId, xcv: guid.xcv }
                        );
                    });
            })
            .catch(error => {
                const errorMessage = error.response ?? error;
                setEnvValue([]);
                trackException(
                    authClient,
                    telemetryClient,
                    'SecondaryHeader',
                    'MSApprovals.SecondaryHeader.fetchEnvironmentList.failure',
                    TrackingEventId.FetchAuthTokenFailure,
                    errorMessage,
                    { environment: context.environment, userAlias: context.userAlias, messageId: guid.messageId, xcv: guid.xcv }
                );
            });
    }, []);

    const onEnvironmentChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        const env = item.text;
        const guid = generateGuid();
        fetchEnvironmentThroughPipeline(env, context.authToken, context.userAlias, guid.messageId, guid.xcv)
            .then((response: { data: string }) => {
                if (response.data.toLowerCase() === env.toLowerCase()) {
                    setContext((prevState: object) => ({ ...prevState, environment: env }));
                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'SecondaryHeader',
                        'MSApprovals.SecondaryHeader.fetchEnvironmentThroughPipeline.Success',
                        TrackingEventId.FetchEnvironmentThroughPipelineSuccess,
                        { environment: context.environment, userAlias: context.userAlias, messageId: guid.messageId, xcv: guid.xcv }
                    );
                }
            })
            .catch(error => {
                const errorMessage = error.response ?? error;
                trackException(
                    authClient,
                    telemetryClient,
                    'SecondaryHeader',
                    'MSApprovals.SecondaryHeader.fetchEnvironmentThroughPipeline.failure',
                    TrackingEventId.FetchEnvironmentThroughPipelineFailure,
                    errorMessage,
                    { environment: context.environment, userAlias: context.userAlias, messageId: guid.messageId, xcv: guid.xcv }
                );
            });
    };

    return (
        <Styled.SecondaryHeaderContainer>
            <Stack horizontal styles={Styled.stackStyles} padding="10px 1%">
                <Stack.Item align="center" className="th-stack-item">
                    {Constant.ENVIRONMENT_NAME}
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        onChange={onEnvironmentChange}
                        placeholder="Select..."
                        options={envValue}
                        selectedKey={envValue.length > 0 && context.environment}
                        defaultSelectedKey={envValue.length > 0 && envValue[0].text}
                        className="th-tenant-dropdown-subheader"
                        responsiveMode={ResponsiveMode.large}
                    />
                </Stack.Item>
            </Stack>
        </Styled.SecondaryHeaderContainer>
    );
}

const connected = withReduxContext(SecondaryHeader);
export { connected as SecondaryHeader };
