import { ITelemetryClient, IAuthClient } from '@employee-experience/common/lib/Models';

export enum TrackingEventId {
    FetchEnvironmentListSuccess = 100000,
    FetchEnvironmentListFailure,
    FetchAuthTokenSuccess,
    FetchAuthTokenFailure,
    FetchEnvironmentThroughPipelineSuccess,
    FetchEnvironmentThroughPipelineFailure,
    FetchTenantInfoSuccess,
    FetchTenantInfoFailure,
    PostBulkDeleteSuccess,
    PostBulkDeleteFailure,
    FetchFormDataSuccess,
    FetchFormDataFailure,
    postFormDataSuccess,
    postFormDataFailure,
    GenerateLoadSuccess,
    GenerateLoadFailure
}

export const getContextCommonTelemetryProperties = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    appAction: string,
    eventName: string,
    eventId: number
): any => {
    const occurenceTime = new Date();
    const correlationId = telemetryClient.getCorrelationId();
    const logData = {
        AppAction: appAction,
        EventOccurenceTime: occurenceTime,
        SessionId: correlationId,
        EventId: eventId,
        EventName: eventName,
        ComponentType: 'Web'
    };
    return logData;
};

export const trackBusinessProcessEvent = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    businessProcessName: string,
    appAction: string,
    eventId: number,
    additionalProperties: any = {}
): void => {
    try {
        const businessProcessProperties = {
            BusinessProcessName: businessProcessName,
            EventType: 'BusinessProcessEvent'
        };
        const contextCommonProperties = getContextCommonTelemetryProperties(
            authClient,
            telemetryClient,
            appAction,
            businessProcessName,
            eventId
        );
        const telemetryProperties = Object.assign(
            businessProcessProperties,
            contextCommonProperties,
            additionalProperties
        );
        telemetryClient.trackEvent({ name: businessProcessName }, telemetryProperties);
    } catch (error) { }
};

export const trackException = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    eventName: string,
    appAction: string,
    eventId: number,
    exception: Error,
    additionalProperties: any = {}
): void => {
    try {
        const contextCommonProperties = getContextCommonTelemetryProperties(
            authClient,
            telemetryClient,
            appAction,
            eventName,
            eventId
        );
        const telemetryProperties = Object.assign(contextCommonProperties, additionalProperties);
        telemetryClient.trackException({ exception: exception, properties: telemetryProperties });
    } catch (error) { }
};