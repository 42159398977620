export const navigationConfig = [
    {
        key: 'Main',
        links: [
            {
                Key: 1,
                name: 'Create Test Request',
                icon: 'CirclePlus',
                href: '/',
                ariaLabel: 'Create Test Request'
            },
            {
                Key: 2,
                name: 'Generate Load',
                icon: 'CPlusPlusLanguage',
                href: '/GenerateLoad',
                ariaLabel: 'Generate Load'
            },
            {
                Key: 3,
                name: 'Bulk Delete Pending Requests',
                icon: 'Delete',
                href: '/BulkDelete',
                ariaLabel: 'Bulk Delete Pending Requests'
            }
        ],
    }
];