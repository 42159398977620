import { IAuthClient } from '@employee-experience/common';
import axios from 'axios';

export function fetchAuthToken(authClient: IAuthClient) {
    return authClient.acquireToken(__RESOURCE_URL__)
}

export function fetchEnvironmentList(authToken: string, userAlias: string, env: string, messageId: string,  xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/Common/GetEnvironment",
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias":userAlias,
                "env":env,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function fetchEnvironmentThroughPipeline(env: string, authToken: string, userAlias: string, messageId: string,  xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/TestHarnessEnvironment/GetEnvironment?env=" + env,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "env":env,
                "userAlias":userAlias,
                "messageId":messageId,
                "xcv":xcv
            }

        }
    )
}

export function fetchTenantInfo(env: string, authToken: string, userAlias: string, messageId: string,  xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/Common/GetSelectOptions/" + env,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "env":env,
                "userAlias":userAlias,
                "messageId":messageId,
                "xcv":xcv
            }

        }
    )
}

export function fetchFormData(tenantId: string, approvarName: string, submitterName: string, authToken: string, env: string,  messageId: string,  xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/SyntheticTransaction/GenerateForm/" + env + "?tenant=" + tenantId + "&approver=" + approvarName + "&submitter=" + submitterName,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "env":env,
                "userAlias":approvarName,
                "messageId":messageId,
                "xcv":xcv
            }

        }
    )
}

export function GenerateLoad(tenantID: string, approvarName: string, load: string, batchSize: string, authToken: string, env: string, userAlias: string, messageId: string,  xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/LoadGenerator/GenerateLoad/" + env + "?Tenant=" + tenantID + "&Approver=" + approvarName + "&Load=" + load + "&Batchsize=" + batchSize,
        null,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`,
                "env":env,
                "userAlias":userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function PostBulkDelete(tenantID: string, approvarName: string, days: string, docNumber: string, authToken: string, env: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/BulkDelete/BulkDeleteDocument/" + env + "?Tenant=" + tenantID + "&Approver=" + approvarName + "&Days=" + days + "&DocNumber=" + docNumber,
        null,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`,
                "env":env,
                "userAlias":userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function postFormData(formData: {}, Tenant: string, authToken: string, env: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/SyntheticTransaction/SubmitPayload/" + env + "?Tenant=" + Tenant,
        JSON.stringify(JSON.stringify(formData)),
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`,
                "env":env,
                "userAlias":userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

