import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from './App.tsx';
import { ShellWithStore } from './ShellWithStore';

ReactDOM.render(
    <ShellWithStore>
        <App />
    </ShellWithStore>,
    document.getElementById('app')
);