export const HelpMainPageContent = "MSApprovals delivers a modern approvals experience" + 
               " for any approval, anywhere, anytime, on any device. It consolidates" +
               " approvals across Microsoft's line of business applications, building" +
               " on modern technology and powered by Microsoft Azure. It serves as a" +
               " showcase for solving modern IT scenarios using the latest technologies.";

export const QuickLinks = [
    {
        text: "Onboard your app's approvals", 
        email: true,
        emailAddress: "approvalspm@microsoft.com",
        subject: "Onboarding",
        body: "",
    },
    {
        text: "Share", 
        email: true,
        emailAddress: "",
        subject: "Check out the MSApprovals application!",
        body: "Hi! Did you know you can now take approval actions on many LOB applications" +
                " from your PC/mobile? I have started using it and thought you might like" +
                " it too.\n\nMSApprovals application syncs with various LOB applications" + 
                " and lets you approve these items while on the go.\n\nYou can access it" +
                " at https://msapprovals.microsoft.com \n\nRegards,",
        alternateText: "Share MSApprovals, opens in a new window link"
    },
    {
        text: "Open support ticket",  
        email: true,
        emailAddress: "",
        body: "",
        subject: "",
        getSupportEmail: true,
    },
    {
        text: "Contact us",  
        email: true,
        emailAddress: "",
        body: "",
        subject: "",
        getSupportEmail: true,
    },
    {
        text: "Learn more",
        email: false,
        link: "http://aka.ms/MSApprovalsDeck",
        alternateText: "Learn more about MSApprovals, opens in a new tab link"
    }
];